/* Wrapper: Ensure responsiveness */
.rdrDateRangePickerWrapper {
  max-width: 100%;
  margin: 0 auto;
  background-color: white;
  border-radius: 10px;
  gap: 10px;
}

/* Left Section: Defined Ranges */
.rdrDefinedRangesWrapper {
  width: 50%;
  margin: 0 auto;
}

/* Calendar Section */
.rdrCalendarWrapper.rdrDateRangeWrapper {
  max-width: 100%;
  margin: 0 auto;
}

/* Calendar Month */
.rdrMonth {
  width: 25rem;
  margin: 0 auto;
}

/* Static Range Label Styling */
.rdrStaticRangeLabel {
  font-size: 16px;
  font-weight: 400;
}

/* Static Range Margins */
.rdrStaticRange {
  margin-top: 10px;
}

/* Responsive Design Adjustments */
@media (max-width: 1424px) {
  .rdrDefinedRangesWrapper {
    width: 30%;
    margin-bottom: 25px; /* Add spacing */
  }

  .rdrCalendarWrapper.rdrDateRangeWrapper {
    max-width: 100%;
    margin: 0 auto;
  }

  .rdrMonth {
    width: 22rem; /* Reduce calendar size slightly */
  }
}
@media (max-width: 1294px) {
  .rdrDefinedRangesWrapper {
    width: 10rem;
    margin-bottom: 25px; /* Add spacing */
  }

  .rdrCalendarWrapper.rdrDateRangeWrapper {
    max-width: 100%;
    margin: 0 auto;
  }

  .rdrMonth {
    width: 25rem; /* Reduce calendar size slightly */
  }
}

@media (max-width: 768px) {
  /* Adjust for smaller screens */
  .rdrMonth {
    width: 20rem;
  }

  .rdrStaticRangeLabel {
    font-size: 14px;
  }
}

@media (max-width: 480px) {
  /* Adjust for very small screens */
  .rdrDateRangePickerWrapper {
    padding: 10px;
  }

  .rdrDefinedRangesWrapper {
    width: 100%; /* Take full width */
  }

  .rdrMonth {
    width: 100%; /* Expand calendar to fit screen */
  }

  .rdrStaticRangeLabel {
    font-size: 12px; /* Even smaller font for compact view */
  }
}
