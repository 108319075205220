// @import url('../fonts.css');
html,
body {
  padding: 0;
  margin: 0;
  // font-family: "SF Pro Display", sans-serif;
  font-family: 'Poppins', sans-serif;
  // background: url(../assets/images/bac.jpg);
  background-color: white;
  background-size: cover;
  background-repeat: no-repeat;
  font-size: medium;
}


a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

.indigobgmedium {
  background-color: #4c39d4;
}
.custom-img {
  background-image: url(../icons/bg-gradient.webp);
  background-size: cover;
  background-repeat: no-repeat;
}

@media (prefers-color-scheme: dark) {
  html {
    color-scheme: dark;
  }
  body {
    color: white;
    background: black;
  }
}

h1,
h2,
h3,
h4,
h5 {
  // font-family: "SF Pro Display", sans-serif;
  font-family: 'Poppins', sans-serif;
}

.landScreen {
  background: #fafafb;
  padding: 0rem 0rem;
}
.containerPage {
  background: rgba(0, 0, 0, 0.359);
  height: 100vh;
  padding: 0.5rem 2rem;
}
.landIconImg {
  width: 100%;
  text-align: center;
  opacity: 0.8;
}
.navImageTab .landIconImg {
  filter: brightness(0) invert(1);
}
.headerback {
  background: rgba(0, 0, 0, 0.058);
}
.headerbackblue {
  background: #ffffffde;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.179);
}
.landScreenBack {
  background: rgb(102, 93, 254);
  background: url(../assets/images/bac.jpg);
  background-size: cover;
  background-repeat: no-repeat;
}

.backButton:hover {
  background: #4b39d498;
}
.cardhover:hover {
  background: rgba(237, 237, 237, 0.335);
  cursor: pointer;
}

.SamIcon {
  height: 20px;
  width: 20px;
  opacity: 0.8;
}
.SideNav .SamIcon {
  height: 25px;
  width: 25px;
}

.SideNav span {
  font-size: 0.1rem;
}

.SideNavTitle span {
  font-size: 0.8rem;
}

span {
  font-size: 1rem;
}

.custom-backface-visibilty {
  transition: all 0.7s ease-in-out;
  transform: translateZ(0);
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  -webkit-transform: translateZ(0) scale(1, 1);
}

/* Custom scrollbar styles for WebKit (Chrome, Safari) */
/* Change the colors and sizes to match your requirements */
::-webkit-scrollbar {
  width: 6px;
  height: 3px;
  border-left: 0;
  background: rgba(0, 0, 0, 0.1);
}

::-webkit-scrollbar-thumb {
  background-color: #4a5568;
  border-radius: 3px;
}

::-webkit-scrollbar-track {
  background-color: #cbd5e0;
}

/* Custom scrollbar styles for Firefox */
/* Change the colors and sizes to match your requirements */
.scrollbar-thin {
  scrollbar-color: #4a5568 #cbd5e0;
  scrollbar-width: thin;
}