.deliveryContianer :where(.css-dev-only-do-not-override-1u9qppd).ant-steps .ant-steps-item-finish .ant-steps-item-icon >.ant-steps-icon{
    color: green !important;
}
 .deliveryContianer :where(.css-dev-only-do-not-override-1u9qppd).ant-steps .ant-steps-item-finish .ant-steps-item-icon{
    background-color: transparent !important;
    border-style: solid !important;
    border-color: green !important;
}
 .deliveryContianer :where(.css-dev-only-do-not-override-1u9qppd).ant-steps .ant-steps-item-process .ant-steps-item-icon {
    background-color: transparent !important;
    border-style: solid !important;
    border-color: green !important;
}
 .deliveryContianer :where(.css-dev-only-do-not-override-1u9qppd).ant-steps .ant-steps-item-process .ant-steps-item-icon >.ant-steps-icon {
    color: green !important;
}
 .deliveryContianer :where(.css-dev-only-do-not-override-1u9qppd).ant-steps .ant-steps-item-finish>.ant-steps-item-container>.ant-steps-item-tail::after {
    background-color: green !important;
}