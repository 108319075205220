.sBar {
  position: fixed;
  left: 200;
  top: 50px;
  height: 100%;
  width: 80px;
  background-color: white;
  transition: width 0.3s ease;
}

.sBar:hover {
  width: 260px;
}

.sBar ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.sBar ul li {
  padding: 10px;
  text-align: center;
  white-space: nowrap;
}

.sBar ul li a {
  color: #fff;
  text-decoration: none;
}

.sBar ul li span {
  display: none;
}

.sBar:hover ul li span {
  display: inline-block;
  margin-left: 10px;
  transition: width 0.3s ease;
}
.sBar:hover ul li b {
    display: none
  }
