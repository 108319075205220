@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  h1 {
    @apply lg:text-base text-sm font-semibold tracking-wide text-[#030229];
  }
  h2 {
    @apply lg:text-base text-sm font-medium tracking-wide text-[#030229];
  }
  h3 {
    @apply lg:text-base text-sm font-normal tracking-wide opacity-80 text-[#030229];
  }
  h4 {
    @apply text-sm font-normal tracking-wide opacity-90 text-[#030229];
  }
}
