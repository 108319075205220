.my-custom-tags {
    display: flex;
    flex-wrap: wrap;
    padding: 1px;
  }
  
  .my-custom-tag-input {
    flex: 1;
  }
  
  .my-custom-tag-input-field {
    width: 100%;
    padding: 8px;
    font-size: 14px;
    border: 1px solid #ccc;
    border-radius: 4px;
    outline: none;
    background-color: #fff; 
  }
  
  .my-custom-selected {
    color: #fff;
    border-radius: 4px;
    margin: 4px;
    padding: 4px 8px;
    width: 100%;
  }
  
  .my-custom-tag {
    background-color: #f0f0f0;
    color: #333;
    border-radius: 4px;
    margin: 0 8px 8px 0;
    padding: 0px 8px;
  }
  
  .my-custom-remove {
    cursor: pointer;
    margin-left: 8px;
  }
  
  .my-custom-suggestions {
    list-style: none;
    padding: 0;
    margin: 0;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .my-custom-active-suggestion {
    background-color: #007bff;
    color: #fff;
  }
  