  #loading-text {
    display: inline-block;
    font-size: 20px;
    /* font-weight: bold; */
    color: #000;
    animation-name: loading;
    animation-duration: 1.5s;
    animation-iteration-count: infinite;
  }
  
  #loading-text .dot {
    animation-name: loading-dot;
    animation-duration: 1.5s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
    opacity: 0;
  }
  
  #loading-text .dot:nth-child(2) {
    animation-delay: 0.5s;
  }
  
  #loading-text .dot:nth-child(3) {
    animation-delay: 1s;
  }
  
  @keyframes loading-dot {
    0% {
      opacity: 0;
    }
    25% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    75% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  