.custom-select :where(.css-dev-only-do-not-override-12w8y0w).ant-select:not(.ant-select-customize-input) .ant-select-selector {
    background-color: #EFF0F6;
    height: 52px;
}
.custom-select :where(.css-dev-only-do-not-override-12w8y0w).ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    padding: 10px 11px;
}
.ant-select-focused:where(.css-dev-only-do-not-override-12w8y0w).ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer) .ant-select-selector{
    border-color: none;
    box-shadow: none;
}
:where(.css-dev-only-do-not-override-12w8y0w).ant-switch{
    min-width: 32;
    height: 19px;
}
:where(.css-dev-only-do-not-override-12w8y0w).ant-switch .ant-switch-handle{
    width: 14px;
    height: 14px;
    /* top: 0.2px; */
}

