:where(.css-dev-only-do-not-override-crq9dz).ant-table-wrapper
  .ant-table-thead
  > tr
  > th,
:where(.css-dev-only-do-not-override-crq9dz).ant-table-wrapper
  .ant-table-thead
  > tr
  > td {
  position: relative;
  color: rgba(0, 0, 0, 0.88);
  font-weight: 600;
  text-align: start;
  background: #f4f6fa;
  border-bottom: 1px solid #e9e9e9;
  transition: background 0.2s ease;
}
