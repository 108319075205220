.custom-checkbox .ant-checkbox-inner {
  background-color: transparent;
  border-color: #babfc4;
}

.custom-checkbox .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #0483fa;
}

.custom-checkbox .ant-checkbox-checked .ant-checkbox-inner::after {
  display: none;
}
