.clickable-row {
  cursor: pointer;
}

@media print {
  body * {
    display: none;
  }
  #print-section, #print-section * {
    display: block;
  }
}
