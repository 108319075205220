
.button-container {
    display: block;
    width: 80px;
   
  }
  .effect-container {
    position: relative;
    overflow: hidden;
  
    --effect-name: ripple;
    --effect-duration: 500ms;
    --effect-top: 0;
    --effect-left: 0;
    --effect-height: 100px;
    --effect-width: 100px;
    --effect-color: "fff";
  }
  
  .effect-container::before {
    content: "";
    position: absolute;
    opacity: 0.3;
    top: var(--effect-top);
    left: var(--effect-left);
    height: var(--effect-height);
    width: var(--effect-width);
    background: var(--effect-color);
  }
  
  .effect-container.ripple.active::before {
    border-radius: 50%;
    animation: ripple var(--effect-duration) linear forwards;
  }
  
  @keyframes ripple {
    from {
      transform: scale(0);
    }
  
    to {
      transform: scale(6);
      opacity: 0;
    }
  }
  
  .effect-container.square-rotate.active::before {
    animation: square-rotate var(--effect-duration) linear forwards;
  }
  
  @keyframes square-rotate {
    from {
      transform: scale(0) rotate(0);
    }
  
    to {
      transform: scale(6) rotate(180deg);
      opacity: 0;
    }
  }
  